<template>
  <div>
    <Spinner v-if="this.loading" />
    <Topbar>
      <div class="actions">
        <router-link to="/posts/create" class="important">
          <span>Create Post</span>
        </router-link>
      </div>
    </Topbar>
    <List v-bind:posts="posts" />
  </div>
</template>

<script>
import Api from '@/utils/api'
import Topbar from '@/components/Topbar'
import List from '@/components/List'
import Spinner from '@/components/Spinner'

export default {
  name: 'Posts',
  data() {
    return {
      posts: [ ],
      loading: true
    }
  },
  mounted() {
    this.loading = true

    Api.get("post").then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          []
        }
      })
      .then((data) => {
        this.posts = data
        this.loading = false
      })
  },
  components: {
    List,
    Topbar,
    Spinner
  }
}
</script>
