<template>
  <div class="Row">
    <div class="Cell Published">
      <div v-bind:class="{On: rowData.published}" />
    </div>
    <div class="Cell Id">
      <span>{{ rowData.id }}</span>
    </div>
    <div class="Cell Title">
      <span>{{ rowData.title }}</span>
    </div>
    <div class="Cell Action">
      <router-link v-bind:to="'/posts/' + rowData.id">
        <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
        </svg>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Row',
  props: {
    rowData: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>
.Row {
  width: 100%;
  margin-top: 0.5rem;
  padding: .5rem 0 .5rem 0;
  display: flex;
  border-radius: 0.25rem;
}

.Row:nth-child(even) {
  --tw-bg-opacity: 0.1;
  background-color: rgba(17,24,39,var(--tw-bg-opacity));
}

.Row:nth-child(odd) {
  --tw-bg-opacity: 0.3;
  background-color: rgba(17,24,39,var(--tw-bg-opacity));
}

.Cell {
  text-align: center;
}

.Cell.Published {
  width: 46px;
}

.Published div {
  border-radius: 100%;
  height: 16px;
  width: 16px;
  background: red;
  display: inline-block;
  box-shadow: inset 0 -15px 15px rgb(255, 0, 10), inset -20px -10px 40px rgba(255, 225, 225, 0.4), 0 0 1px #000;
}

.Cell.Published div.On {
  box-shadow: inset 0 -15px 15px rgb(0, 255, 10), inset -20px -10px 40px rgba(255, 225, 225, 0.4), 0 0 1px #000;
}

.Cell.Id {
  width: 46px;
}

.Cell.Title {
  width: calc(100% - 138px);
  text-align: left;
}

.Cell.Action {
  width: 46px;
}

.Cell.Action svg {
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  stroke: currentColor;
  display: block;
  vertical-align: middle;
}
</style>
