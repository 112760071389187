<template>
  <div class="List">
    <div class="Header"></div>

    <Row
      v-for="post of posts"
      v-bind:rowData="post"
    />

    <div class="Footer">
    </div>
  </div>
</template>

<script>
import Row from '@/components/List/Row'
export default {
  name: 'List',
  props: ['posts'],
  components: {
    Row
  }
}
</script>

<style scoped>
.List {
  width: 100%;
  padding: 0.1rem;
}

.Header, .Footer {
  background: #4fd3e9;
}
</style>
